import gql from "graphql-tag";

export const GET_DETAILS_CODEGROUPS = gql`
  query serialCodes($id: String!) {
    serialCodes(serialCodeGroupID: $id){
        id
        code
        point
        expirationTime
        status
        exchangedTime
        exchangedBy{
            username
        }
        group{
             id
        }
    }
  }
`;
