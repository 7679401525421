/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useRef, useEffect, useState } from 'react';
import { withStyle } from 'baseui';
import {
  Grid,
  Row as Rows,
  Col as Column,
} from '../../components/FlexBox/FlexBox';
import Button from '../../components/Button/Button';
import { Wrapper, Header, Heading } from '../../components/WrapperStyle';
import QRCode from 'qrcode.react';
import {
  TableWrapper,
  StyledTable,
  StyledHeadCell,
  StyledBodyCell,
  StyledCell,
  Imaged,
  ImagedFont,
} from './ListOfCodes.style';

import NoResult from '../../components/NoResult/NoResult';
import { useHistory } from 'react-router-dom';
import '../../containers/ListOfCodes/ListOfCodes.css';
import {
  DASHBOARD,
  DESIGNUIQRCODE,
  CONFIRMLISTOFCODES,
} from '../../settings/constants';
import { connect, useSelector } from 'react-redux';
import moment from 'moment';
import { DatePicker } from 'antd';
import { Font, StyleSheet } from '@react-pdf/renderer';
import ReactPDF from '@react-pdf/renderer';
import '../../containers/Dashboard/Dashboard.css';
import DesignPDF from './DesignUICode';
import { PDFExport } from '@progress/kendo-react-pdf';
import '../ListOfCodes/QR.css';
import font from './../../image/front.png';
import back from './../../image/back.png';
import Pdf from 'react-to-pdf';
import Text2Image from '@xg4/text2image';
import { PDFViewer } from '@react-pdf/renderer';
import { fontWeight } from 'html2canvas/dist/types/css/property-descriptors/font-weight';
import { Modal } from 'antd';
import 'antd/dist/antd.css';
import { Form, InputNumber } from 'antd';
import { CSVLink, CSVDownload } from 'react-csv';
import CsvDownloader from 'react-csv-downloader';
const Col = withStyle(Column, () => ({
  '@media only screen and (max-width: 767px)': {
    marginBottom: '20px',
    ':last-child': {
      marginBottom: 0,
    },
  },
}));

const Row = withStyle(Rows, () => ({
  '@media only screen and (min-width: 768px)': {
    alignItems: 'center',
  },
}));

Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: `/Roboto-Regular.ttf`,
    },
    {
      src: `/Roboto-Bold.ttf`,
      fontWeight: 'bold',
    },
    {
      src: `/Roboto-Italic.ttf`,
      fontWeight: 'normal',
      fontStyle: 'italic',
    },
    {
      src: `/Roboto-BoldItalic.ttf`,
      fontWeight: 'bold',
      fontStyle: 'italic',
    },
  ],
});
const dateFormat = 'YYYY/MM/DD';
var today = '';
today = moment().format(dateFormat);


const PreviewExportPDF = (props) => {
  let Data = [];
  const [isGeneratingPDF, setIsGeneratingPDF] = useState(false);
  const [isGeneratingCSV, setIsGeneratingCSV] = useState(false);
  let history = useHistory();

  const [isOpenEdit, setIsOpenEdit] = React.useState(true);
  const BacktoGenerate = () => {
    history.replace(DASHBOARD);
    window.location.reload();
  };

  const [offsetHeight, setoffsetHeight] = useState(
    Number(localStorage.getItem('offsetHeight')) || 489
  );

  const [offsetWidth, setoffsetWidth] = useState(
    Number(localStorage.getItem('offsetWidth')) || 758
  );

  const [marginLeft, setmarginLeft] = useState(
    Number(localStorage.getItem('marginLeft')) || 11
  );
  const [marginRight, setmarginRight] = useState(
    Number(localStorage.getItem('marginRight')) || 11
  );
  const [marginTop, setmarginTop] = useState(
    Number(localStorage.getItem('marginTop')) || 14
  );
  const [marginBottom, setmarginBottom] = useState(
    Number(localStorage.getItem('marginBottom')) || 14
  );
  const pdfExportComponent = useRef(null);
  let limit = 10;
  let max = props.codePre.serialCodes?.length * 2;

  const styleSizeHeightCard = {
    objectFit: 'contain',
    // height: offsetHeight / 2,
    // width: offsetWidth / 5,
    height: '100%',
    width: '100%',
  };

  const styleSizeHeightCardBack = {
    objectFit: 'contain',
    height: offsetHeight / 2,
    width: offsetWidth / 5,
  };
  const pageHoz1 = {
    backgroundColor: '#29679e',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
    gridTemplateRows: '1fr 1fr',
    width: '100%',
    height: '100%',
  };
  const fontCardstyle = {
    backgroundColor: '#29679e',
    width: '842px',
    height: '595px',
    paddingTop: marginTop + 'mm',
    paddingBottom: marginBottom + 'mm',
    paddingLeft: marginLeft + 'mm',
    paddingRight: marginRight + 'mm',
  };
  const pageHoz2 = {
    backgroundColor: '#ffffff',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
    gridTemplateRows: '1fr 1fr',
    width: '100%',
    height: '100%',
  };
  const backCardStyle = {
    backgroundColor: '#ffffff',
    width: '842px',
    height: '595px',
    paddingTop: marginTop + 'mm',
    paddingBottom: marginBottom + 'mm',
    paddingLeft: marginLeft + 'mm',
    paddingRight: marginRight + 'mm',
  };

  let marginPositionCard  = 0;
  if(marginLeft >=5 &&  marginLeft < 10){
   marginPositionCard = (37.5 - ((37.5 - 14 )/13)*(marginTop-1)) - marginLeft;
  }
  else if((marginLeft === 1 || marginLeft === 0) && (marginTop ===1 || marginTop ===0) ){
    marginPositionCard = (37.5 - 6);
  }
  else if(marginLeft ===2 && marginTop ===2 ){
    marginPositionCard = (37.5 - 8);
  }
  else if(marginLeft ===3 && marginTop ===3 ){
    marginPositionCard = (37.5 - 10.5);
  }
  else{
    marginPositionCard = (37.5 - ((37.5 - 14 )/13)*(marginTop-1));
  }

  const positionDeadline = {
    marginTop:   marginPositionCard    + 'px',
    marginLeft: '45%',
    fontSize: '10px',
    color: 'rgb(96, 96, 96)',
  };

  console.log(marginPositionCard,"marginPositionCard");
  
  const ItemQRs = props.codePre.serialCodes?.map((item, index, arr) => {
    return (
      <div style={{ position: 'relative' }}>
        <Imaged
          style={styleSizeHeightCardBack}
          // style={{ height: '100%', width: '100%' }}
          src={back}
          alt='back'
        />
        <div className='itemqrfake'>
          <QRCode
            id='qrcode'
            value={
              'https://chubu.web-mirai.jp/mirai?serviceid=chance_card&serialcode=' +
              item?.code
            }
            size={1024}
            level={'L'}
            includeMargin={true}
          />
          <div className='centered1'>{item?.code || 0}</div>
          <div style={positionDeadline}>{item?.expirationTime}</div>
          {/* <div className='itemqr1'>{item?.expirationTime}</div> */}
        </div>
      </div>
    );
  });

  const ItemCARDsfake = Array.from({ length: 10 }, () => {
    return (
      // <div className='itemFontCard1'>
      <Imaged style={styleSizeHeightCard} src={font} alt='font' />
      // </div>
    );
  });

  for (let i = 0; i < Math.ceil(max / (limit * 2)); i++) {
    const fontCard = (
      <div style={fontCardstyle}>
        <div id='fontcard' style={pageHoz1}>
          {ItemCARDsfake.slice(0, limit)}
        </div>
      </div>
    );
    Data.push(fontCard);
    const backCard = (
      <div style={backCardStyle}>
        <div style={pageHoz2}>
          {ItemQRs.slice(i * limit, i * limit + limit)}
        </div>
      </div>
    );
    Data.push(backCard);
  }

  const [modal, setModal] = useState(false);
  const showModal = () => {
    setModal(true);
  };

  const formItemLayout = {
    labelCol: {
      span: 10,
    },
  };

  const onFinish = (values) => {
    setmarginLeft(values.inputleft);
    localStorage.setItem('marginLeft', values.inputleft);
    setmarginRight(values.inputright);
    localStorage.setItem('marginRight', values.inputright);

    setmarginTop(values.inputtop);
    localStorage.setItem('marginTop', values.inputtop);
    let marginNew = values.inputtop;

    setmarginBottom(values.inputbottom);
    localStorage.setItem('marginBottom', values.inputbottom);

    setoffsetHeight(document.getElementById('fontcard').offsetHeight);
    localStorage.setItem(
      'offsetHeight',
      String(document.getElementById('fontcard').offsetHeight)
    );
    setoffsetWidth(document.getElementById('fontcard').offsetWidth);
    localStorage.setItem(
      'offsetWidth',
      String(document.getElementById('fontcard').offsetWidth)
    );

    setModal(false);
  };

  const handleOk = (e) => {
    console.log(e);
    setModal(false);
  };

  const handleCancel = (e) => {
    console.log(e);
    setModal(false);
  };

  const csvData = props.codePre.serialCodes?.map((item) => [
    item?.code,
    'https://chubu.web-mirai.jp/mirai?serviceid=chance_card&serialcode=' +
      item?.code,
  ]);

  return (
    <Grid fluid={true}>
      <Row>
        <Col md={12}>
          <Wrapper style={{ boxShadow: '0 0 5px rgba(0, 0 , 0, 0.05)' }}>
            <TableWrapper>
              <StyledTable $gridTemplateColumns='minmax(70px, 100px) minmax(200px, auto) minmax(100px, auto) minmax(150px, auto) minmax(100px, auto)'>
                <StyledHeadCell>＃</StyledHeadCell>
                <StyledHeadCell>シリアルコード</StyledHeadCell>
                <StyledHeadCell>ポイント数</StyledHeadCell>
                <StyledHeadCell>有効期限</StyledHeadCell>
                <StyledHeadCell>ステータス</StyledHeadCell>
                {props.codePre.serialCodes?.map((p, i) => {
                  return (
                    <React.Fragment>
                      <StyledCell style={{ textAlign: 'center' }}>
                        {i + 1}
                      </StyledCell>
                      <StyledCell style={{ textAlign: 'center' }}>
                        {p?.code}
                      </StyledCell>
                      <StyledCell style={{ textAlign: 'center' }}>
                        {p?.point}
                      </StyledCell>
                      <StyledCell style={{ textAlign: 'center' }}>
                        <DatePicker
                          style={{ marginTop: -20 }}
                          defaultValue={moment(p?.expirationTime)}
                          format={dateFormat}
                          disabled={isOpenEdit}
                        />
                      </StyledCell>
                      <StyledCell style={{ textAlign: 'center' }}>
                        {p?.status === 'valid' ? '有効' : '無効'}
                      </StyledCell>
                    </React.Fragment>
                  );
                })}
              </StyledTable>
            </TableWrapper>
          </Wrapper>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Row>
            <Col md={3}>
              {' '}
              <Modal
                title='PDF Margin A4 Setting'
                visible={modal}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={null}
              >
                <Form
                  name='validate_other'
                  {...formItemLayout}
                  onFinish={onFinish}
                  initialValues={{
                    inputleft: marginLeft,
                    inputright: marginRight,
                    inputtop: marginTop,
                    inputbottom: marginBottom,
                  }}
                >
                  <div style={{ display: ' flex', marginLeft: 55 }}>
                    <Form.Item label=''>
                      <div style={{ display: ' flex' }}>
                        <span
                          className='ant-form-text'
                          style={{ marginTop: 5 }}
                        >
                          {' '}
                          Left:{' '}
                        </span>
                        <Form.Item name='inputleft' noStyle>
                          <InputNumber min={0} max={100} />
                        </Form.Item>
                        <span
                          className='ant-form-text'
                          style={{ marginTop: 5 }}
                        >
                          {' '}
                          mm
                        </span>
                      </div>
                    </Form.Item>
                    <Form.Item label=''>
                      <span
                        className='ant-form-text'
                        style={{ marginTop: 5, marginLeft: 33 }}
                      >
                        {' '}
                        Right:{' '}
                      </span>
                      <Form.Item name='inputright' noStyle>
                        <InputNumber min={0} max={100} />
                      </Form.Item>
                      <span className='ant-form-text'> mm</span>
                    </Form.Item>
                  </div>

                  <div style={{ display: ' flex', marginLeft: 55 }}>
                    <Form.Item label=''>
                      <div style={{ display: ' flex' }}>
                        <span
                          className='ant-form-text'
                          style={{ marginTop: 5 }}
                        >
                          {' '}
                          Top:{' '}
                        </span>
                        <Form.Item name='inputtop' noStyle>
                          <InputNumber min={0} max={100} />
                        </Form.Item>
                        <span
                          className='ant-form-text'
                          style={{ marginTop: 5 }}
                        >
                          {' '}
                          mm
                        </span>
                      </div>
                    </Form.Item>
                    <Form.Item label=''>
                      <span
                        className='ant-form-text'
                        style={{ marginTop: 5, marginLeft: 20 }}
                      >
                        {' '}
                        Bottom:{' '}
                      </span>
                      <Form.Item name='inputbottom' noStyle>
                        <InputNumber min={0} max={100} />
                      </Form.Item>
                      <span className='ant-form-text'> mm</span>
                    </Form.Item>
                  </div>

                  <Form.Item
                    wrapperCol={{
                      span: 5,
                      offset: 10,
                    }}
                  >
                    <Button
                      style={{ marginTop: 30, height: 30 }}
                      type='primary'
                      htmlType='submit'
                    >
                      OK
                    </Button>
                  </Form.Item>
                </Form>
              </Modal>
            </Col>
            <Col
              md={6}
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Button
                style={{ width: 120, background: '#fff', color: '#000' }}
                onClick={BacktoGenerate}
                disabled={isGeneratingPDF}
              >
                閉じる
              </Button>
              <Button
                style={{ width: 120 }}
                className='export-pdf'
                isLoading={isGeneratingPDF}
                onClick={() => {
                  setIsGeneratingPDF(true);
                  pdfExportComponent.current.save(() => {
                    setIsGeneratingPDF(false);
                  });
                }}
              >
                PDF出力
              </Button>
              <CsvDownloader
                bom={false}
                prefix={false}
                suffix={false}
                filename={'CSV_CodeGenerate_' + today + '.csv'}
                datas={csvData}
              >
                <Button
                  style={{ width: 120 }}
                  className='export-pdf'
                  onClick={() => {}}
                >
                  CSV出力
                </Button>
              </CsvDownloader>
              {/* <CSVLink
                data={csvData}
                filename={"CSV_CodeGenerate_" + today + ".csv"}
              >
                <Button
                  style={{ width: 120 }}
                  className='export-pdf'
                  onClick={() => {}}
                >
                  CSV出力
                </Button>
              </CSVLink> */}
            </Col>
            <Col
              md={3}
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Button
                style={{
                  width: 150,
                  background: '#fff',
                  color: '#000',
                }}
                onClick={showModal}
                disabled={isGeneratingPDF}
              >
                PDF Setting
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col className='bottom-Generate' md={12}>
          <div>
            <div style={{ position: 'absolute', left: '-100000px', top: 0 }}>
              <PDFExport
                paperSize='A4'
                landscape={true}
                fileName={'ChanceCard_' + today}
                ref={pdfExportComponent}
              >
                {Data.length !== 0 && <div>{Data}</div>}
              </PDFExport>
            </div>
          </div>
        </Col>
      </Row>
    </Grid>
  );
};

const mapStatetoProps = (state) => {
  return {
    codePre: state.addCodes,
  };
};

export default connect(mapStatetoProps, null)(PreviewExportPDF);
