/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
  useContext,
  useEffect,
  useCallback,
  useState,
  useRef,
} from "react";
import Moment from "react-moment";
import { withStyle } from "baseui";
import {
  Grid,
  Row as Rows,
  Col as Column,
} from "../../components/FlexBox/FlexBox";
import { useDrawerDispatch } from "../../context/DrawerContext";
import Select from "../../components/Select/Select";
import Input from "../../components/Input/Input";
import Button from "../../components/Button/Button";

import { Plus } from "../../components/AllSvgIcon";

import gql from "graphql-tag";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { Wrapper, Header, Heading } from "../../components/WrapperStyle";
import QRCode from "qrcode.react";

import html2canvas from "html2canvas";
import jsPDF from "jspdf";

import {
  TableWrapper,
  StyledTable,
  StyledHeadCell,
  StyledBodyCell,
  StyledCell,
  Imaged,
  ImagedFont,
} from "./ListOfCodes.style";

import NoResult from "../../components/NoResult/NoResult";
import { useHistory } from "react-router-dom";
import "../../containers/ListOfCodes/ListOfCodes.css";

import { DASHBOARD, CONFIRMLISTOFCODES } from "../../settings/constants";
import { connect, useSelector } from "react-redux";
import { IStore } from "../../models";
import { StatefulSelect } from "baseui/select";
import { StyledCell2 } from "../Category/Category.style";
import moment from "moment";
import {
  FormFields,
  FormLabel,
  Error,
} from "../../components/FormFields/FormFields";
import { Code } from "react-content-loader";
import { DatePicker } from "antd";
import { GENERATE_CODE_QUERY } from "../../graphql/query/generateSerialCodes.query";
import { CREATE_SERIAL_CODE } from "../../graphql/mutation/createcodes.mutation";

import { notification, Space } from "antd";
import { setCodes } from "../../redux/addCodes";
import { Page, View, Document, StyleSheet } from "@react-pdf/renderer";
import { PDFViewer } from "@react-pdf/renderer";
import ReactPDF from "@react-pdf/renderer";
import "../../containers/Dashboard/Dashboard.css";
import "../ListOfCodes/QR.css";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { PDFExport } from "@progress/kendo-react-pdf";
import font from "./../../image/front.png";
import back from "./../../image/back.png";
import Pdf from "react-to-pdf";
import ReactDOM from "react-dom";
import Text2Image from "@xg4/text2image";
import { Path, Text, Group, exportSVG } from "@progress/kendo-drawing";
import { saveAs } from "@progress/kendo-file-saver";
import { Modal } from "antd";
import "antd/dist/antd.css";
import {
  Form,
  InputNumber,
  Switch,
  Radio,
  Slider,
  // Button,
  Upload,
  Rate,
  Checkbox,
  Row,
  Col,
} from "antd";
import { UploadOutlined, InboxOutlined } from "@ant-design/icons";
import { relative } from "path";
const ti = new Text2Image();

let listnewQR = [];
let imgData = "";
var today = "";
const dateFormat = "YYYY/MM/DD";

today = moment().format(dateFormat);

const DesignPDF = (props) => {
  let arrayData = [];
  let arrayDataCard = [];
  let arrayDataQR = [];

  const [marginLeft, setmarginLeft] = useState("11");
  const [marginRight, setmarginRight] = useState("11");
  const [marginTop, setmarginTop] = useState("14");
  const [marginBottom, setmarginBottom] = useState("14");

  const pageHoz1 = {
    border: "1px #d3d3d3 solid",
    backgroundColor: "#59a845",
    boxShadow: "0 0 5px rgba(0, 0, 0, 0.1)",
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
    gridTemplateRows: "1fr 1fr",
    // width: "842px",
    height: "595px",
    // paddingTop: marginTop + "mm",
    // paddingBottom: marginBottom + "mm",
    // paddingLeft: marginLeft + "mm",
    // paddingRight: marginRight + "mm",
  };

  const pageHoz2 = {
    border: "1px #d3d3d3 solid",
    backgroundColor: "#f21",
    boxShadow: "0 0 5px rgba(0, 0, 0, 0.1)",
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
    gridTemplateRows: "1fr 1fr",
    width: "842px",
    height: "595px",
  };

  const pdfExportComponent = useRef(null);
  var arr1 = Array.from(
    {
      length: 10,
    },
    function (v, k) {
      return k;
    }
  );

  let limit = 10;
  let max = props.codeQR.serialCodes.length * 2;
  const itemFontCard1 = {};
  const listCARDfake = Array.from({ length: 10 }, () => {
    return (
      <div style={itemFontCard1}>
        <Imaged src={font} alt='font' />
      </div>
    );
  });

  const itemqrfake = {
    position: "absolute",
    top: "40%",
    left: "25%",
    // display: "flex",
  };

  const centered1 = {
    position: "absolute",
    top: "65%",
    left: "20%",
    fontSize: 20,
  };

  const itemqr1 = {
    position: "absolute",
    fontFamily: "arial",
    top: "80%",
    left: "50%",
    color: "rgb(96, 96, 96)",
  };

  const listQR = arr1.map((item, index, arr) => {
    return (
      <div style={{ position: "relative" }}>
        <Imaged src={back} alt='back' />
        <div style={itemqrfake}>
          <QRCode
            id='qrcode'
            value='999999999'
            size={90}
            level={"H"}
            includeMargin={true}
          />
        </div>
        <div style={centered1}>1234567890</div>
        <div style={itemqr1}>2020/20/20</div>
        {/* <img src={linkimg} alt='alt'></img> */}
      </div>
    );
  });

  for (let i = 0; i < Math.ceil(max / (limit * 2)); i++) {
    const backCard = (
      <div style={pageHoz2}>{listQR.slice(i * limit, i * limit + limit)}</div>
    );
    arrayData.push(backCard);
    const fontCard = <div style={pageHoz1}>{listCARDfake.slice(0, limit)}</div>;
    arrayData.push(fontCard);
  }

  const [modal, setModal] = useState(false);
  const showModal = () => {
    // exportScene();
    setModal(true);
  };

  const handleOk = (e) => {
    console.log(e);
    setModal(false);
  };

  const handleCancel = (e) => {
    console.log(e);
    setModal(false);
  };

  const formItemLayout = {
    labelCol: {
      span: 6,
    },
    wrapperCol: {
      span: 14,
    },
  };

  const onFinish = (values) => {
    if (values.inputtop > 14) {
      alert(
        "Nếu chọn margin top > 14 thì từ Card thứ 6 trở đi sẽ qua trang A4 mới, Vì kích thước ChanceCard được cố định."
      );
    }
    setmarginLeft(values.inputleft);
    setmarginRight(values.inputright);
    setmarginTop(values.inputtop);
    setmarginBottom(values.inputbottom);
    setModal(false);
  };

  const changeLeft = () => {};
  const changeRight = () => {};
  const changeTop = () => {};
  const changeBottom = () => {};
  return (
    <div>
      <Modal
        title='PDF Margin A4 Setting'
        visible={modal}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <Form
          name='validate_other'
          {...formItemLayout}
          onFinish={onFinish}
          initialValues={{
            inputleft: 11,
            inputright: 11,
            inputtop: 14,
            inputbottom: 14,
          }}
        >
          <Form.Item label='Left'>
            <Form.Item name='inputleft' noStyle>
              <InputNumber min={0} max={100} onChange={changeLeft} />
            </Form.Item>
            <span className='ant-form-text'> mm</span>
          </Form.Item>
          <Form.Item label='Right'>
            <Form.Item name='inputright' noStyle>
              <InputNumber min={0} max={100} onChange={changeRight} />
            </Form.Item>
            <span className='ant-form-text'> mm</span>
          </Form.Item>
          <Form.Item label='Top'>
            <Form.Item name='inputtop' noStyle>
              <InputNumber min={0} max={100} onChange={changeTop} />
            </Form.Item>
            <span className='ant-form-text'> mm</span>
          </Form.Item>
          <Form.Item label='Bottom'>
            <Form.Item name='inputbottom' noStyle>
              <InputNumber min={0} max={100} onChange={changeBottom} />
            </Form.Item>
            <span className='ant-form-text'> mm</span>
          </Form.Item>
          <Form.Item
            wrapperCol={{
              span: 12,
              offset: 6,
            }}
          >
            <Button type='primary' htmlType='submit'>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <Button style={{ width: 120 }} className='export-pdf' onClick={showModal}>
        SettingPDF
      </Button>
      <Button
        style={{ width: 120 }}
        className='export-pdf'
        onClick={() => {
          pdfExportComponent.current.save();
        }}
      >
        PDF出力
      </Button>
      {/* <div style={{ position: "absolute", left: "-1000px", top: 0 }}> */}
      <PDFExport
        paperSize='A4'
        landscape='true'
        fileName={"ChanceCard" + today}
        ref={pdfExportComponent}
      >
        {arrayData.length !== 0 && <div>{arrayData}</div>}
      </PDFExport>
      {/* </div> */}
    </div>
  );
};

const mapStatetoProps = (state) => {
  return {
    codeQR: state.addCodes,
  };
};

export default connect(mapStatetoProps, null)(DesignPDF);
