import React, { useContext, lazy, Suspense } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import {
  LOGIN,
  DASHBOARD,
  LISTOFCODES,
  CONFIRMLISTOFCODES,
  PREVIEWEXPORTPDF,
  HISTORY,
  DESIGNUIQRCODE,
  DETAILSHISTORY,
  SETTINGS,
} from "./settings/constants";
import AuthProvider, { AuthContext } from "./context/auth";
import { InLineLoader } from "./components/InlineLoader/InlineLoader";
import ListOfCodes from "./containers/ListOfCodes/ListOfCodes";
import ConfirmListOfCodes from "./containers/ConfirmListofCodes/ConfirmListofCodes";
import DetailsHistory from "./containers/History/DetailsHistory";
import PreviewExportPDF from "./containers/ListOfCodes/PreviewExportPDF";
import DesignUICode from "./containers/ListOfCodes/DesignUICode";
const AdminLayout = lazy(() => import("./containers/Layout/Layout"));
const Dashboard = lazy(() => import("./containers/Dashboard/Dashboard"));
const History = lazy(() => import("./containers/History/History"));
const Settings = lazy(() => import("./containers/Settings/Settings"));
const Login = lazy(() => import("./containers/Login/Login"));
const NotFound = lazy(() => import("./containers/NotFound/NotFound"));

/**
 *
 *  A wrapper for <Route> that redirects to the login
 * screen if you're not yet authenticated.
 *
 */

function PrivateRoute({ children, ...rest }) {
  const { isAuthenticated } = useContext(AuthContext);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

const Routes = () => {
  return (
    <AuthProvider>
      <Suspense fallback={<InLineLoader />}>
        <Switch>
          <PrivateRoute exact={true} path={DASHBOARD}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Dashboard />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={HISTORY}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <History />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={SETTINGS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Settings />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={DETAILSHISTORY}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <DetailsHistory />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={LISTOFCODES}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <ListOfCodes />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={CONFIRMLISTOFCODES}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <ConfirmListOfCodes />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={PREVIEWEXPORTPDF}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <PreviewExportPDF />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>

          <Route path={DESIGNUIQRCODE}>
            <DesignUICode />
          </Route>

          <Route path={LOGIN}>
            <Login />
          </Route>
          <Route component={NotFound} />
        </Switch>
      </Suspense>
    </AuthProvider>
  );
};

export default Routes;
