import React from 'react';
import { useMutation } from '@apollo/react-hooks';
import { LOGIN } from '../graphql/mutation/login.mutation';
import jwt from 'jsonwebtoken';
import { useDispatch } from 'react-redux';
import { setUserLogin, setUserLoginId } from '../redux/common';

type AuthProps = {
  isAuthenticated: boolean;
  role: string;
  authenticate: Function;
  signout: Function;
};

export const AuthContext = React.createContext({} as AuthProps);
export const Role = {
  admin: 'admin',
  hanbaiten: '',
};

const ValidToken = () => {
  const token = localStorage.getItem('access_token');
  // JWT decode & check token validity & expiration.
  if (token) {
    let auth = jwt.decode(token);
    let timenow = new Date().getTime();
    if (timenow < auth.exp * 1000) {
      return { isValid: true, role: auth.role };
    }
  }
  return {
    isValid: false,
    role: null,
  };
};

const AuthProvider = (props: any) => {
  const [isAuthenticated, makeAuthenticated] = React.useState(
    ValidToken().isValid
  );
  const [role, setRole] = React.useState<any>(ValidToken().role);

  const dispatch = useDispatch();

  let userLoginId;

  const [login, { data, loading }] = useMutation(LOGIN, {
    onError: (error) => {
      alert(error);
    },
    onCompleted: (data) => {
      if (data.login.userType != 'admin' && data.login.userType != '') {
        alert('Invalid username or password');
        return;
      }
      makeAuthenticated(true);
      if (data.login.userType === 'admin') setRole(Role.admin);
      else setRole(Role.hanbaiten);
      localStorage.setItem('access_token', data.login.access_token);
      let iExpiresTime = parseInt(data.login.expiresTime);
      let timenow = new Date().getTime();
      setTimeout(() => {
        if (iExpiresTime - timenow <= 0) {
          localStorage.removeItem('access_token');
          makeAuthenticated(false);
        }
      }, iExpiresTime - timenow); // fake async
    },
  });

  function authenticate({ username, password }, cb) {
    login({ variables: { username, password } }).then((data) => {
      localStorage.setItem('userLoginId', data?.data.login.id);
      localStorage.setItem('userNameLogin', data?.data.login.username);
      const userLoginId = localStorage.getItem('userLoginId');
      dispatch(setUserLoginId(userLoginId));
    });
  }

  function signout(cb) {
    makeAuthenticated(false);
    setRole(null);
    localStorage.removeItem('access_token');
    localStorage.removeItem('userLoginId');
    setTimeout(cb, 100);
  }

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        authenticate,
        signout,
        role,
      }}
    >
      <>{props.children}</>
    </AuthContext.Provider>
  );
};

// const isValidToken = () => {
//   const token = localStorage.getItem('pickbazar_token');
//   // JWT decode & check token validity & expiration.
//   if (token) return true;
//   return false;
// };

// const AuthProvider = (props: any) => {
//   const [isAuthenticated, makeAuthenticated] = React.useState(isValidToken());
//   function authenticate({ email, password }, cb) {
//     makeAuthenticated(true);
//     localStorage.setItem('pickbazar_token', `${email}.${password}`);
//     setTimeout(cb, 100); // fake async
//   }
//   function signout(cb) {
//     makeAuthenticated(false);
//     localStorage.removeItem('pickbazar_token');
//     setTimeout(cb, 100);
//   }
//   return (
//     <AuthContext.Provider
//       value={{
//         isAuthenticated,
//         authenticate,
//         signout,
//       }}
//     >
//       <>{props.children}</>
//     </AuthContext.Provider>
//   );
// };

export default AuthProvider;
