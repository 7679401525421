import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import logger from "redux-logger";

import common from "./common";
import users from "./users";
import addCodes from "./addCodes";
import listCodes from "./listCodes";
import editCodes from "./editCodes";

const reducers = {
    common,
    users,
    addCodes,
    editCodes,
}

const store = createStore(
    combineReducers(reducers),
    applyMiddleware(thunk, logger)
);

export default store;