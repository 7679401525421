// **************** ROUTE CONSTANT START **************************
// General Page Section

export const DASHBOARD = "/";
export const PRODUCTS = "/products";
export const CATEGORY = "/category";
export const LOGIN = "/login";
export const LOGOUT = "/logout";
export const ORDERS = "/orders";
export const CUSTOMERS = "/customers";
export const HISTORY = "/history";
let idGroup = "";
export const DETAILSHISTORY = "/history-details/" + idGroup;
export const COUPONS = "/coupons";
export const SETTINGS = "/settings";
export const STAFF_MEMBERS = "/staff-members";
export const LISTOFCODES = "/list-of-codes";
export const CONFIRMLISTOFCODES = "/confirm-list-of-codes";
export const PREVIEWEXPORTPDF = "/exportpdf";
export const DESIGNUIQRCODE = "/desigqrcode";

export const SITE_SETTINGS = "/site-settings";
// **************** ROUTE CONSTANT END **************************

export const CURRENCY = "$";
