import { ICode } from '../models';

const SET_CODE = 'SET_CODE';

export const setCodes = (codes: ICode) => (dispatch: any) => {
  dispatch({ type: SET_CODE, codes });
};

const initialState: ICode = {
  target: '',
  memo: '',
  card: '',
  serialCodes: [
    {
      code: '',
      point: 0,
      expirationTime: 0,
      status: '',
    },
  ],
};

export default function CodesReducer(state = initialState, action: any) {
  switch (action.type) {
    case SET_CODE:
      return {
        ...state,
        ...action.codes,
      };
    default:
      return state;
  }
}
