/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
import Moment from "react-moment";
import { withStyle } from "baseui";
import {
  Grid,
  Row as Rows,
  Col as Column,
} from "../../components/FlexBox/FlexBox";
import Input from "../../components/Input/Input";
import { useQuery, useMutation } from "@apollo/react-hooks";
import {
  TableWrapper,
  StyledTable,
  StyledHeadCell,
  StyledBodyCell,
  StyledBodyCellIndex,
} from "./ListOfCodes.style";
import {
  FormFields,
  FormLabel,
  Error,
} from "../../components/FormFields/FormFields";
import { DatePicker } from "antd";
import Button from "../../components/Button/Button";
import moment from "moment";
import { StatefulSelect } from "baseui/select";
import Select from "../../components/Select/Select";
import { useDispatch } from "react-redux";
import { setCodes } from "../../redux/addCodes";

const Col = withStyle(Column, () => ({
  "@media only screen and (max-width: 767px)": {
    marginBottom: "20px",

    ":last-child": {
      marginBottom: 0,
    },
  },
}));
const Row = withStyle(Rows, () => ({
  "@media only screen and (min-width: 768px)": {
    alignItems: "center",
  },
}));

const statusSelectOptions = [
  { value: "invalid", name: "無効" },
  { value: "valid", name: "有効" },
];

const pointsSelectOptions = [
  { value: "1", name: "1" },
  { value: "2", name: "2" },
  { value: "3", name: "3" },
  { value: "4", name: "4" },
  { value: "5", name: "5" },
  { value: "6", name: "6" },
  { value: "7", name: "7" },
  { value: "8", name: "8" },
  { value: "9", name: "9" },
  { value: "10", name: "10" },
];

const dateFormat = "YYYY/MM/DD";

let dateExpri = 0;

const FormEditCode = (props) => {
  const dispatch = useDispatch();

  const {
    code: icode,
    point: ipoint,
    expirationTime: ieixpirationTime,
    status: istatus,
  } = props.toCode;

  const [codeProps, setCodesProps] = useState(icode);
  const [points, setPoints] = useState(ipoint);
  const [expriDate, setexpriDate] = useState(props.toCode.expirationTime);
  const [statuschange, setstatuschange] = useState(istatus);

  const handleChangeStatus = ({ value }) => {
    setstatuschange(value[0].value);
  };
  const handleChangePoints = ({ value }) => {
    setPoints(value[0].value);
  };

  let dates = [];
  dates.push(new Date("2020/10/10"));
  let now = new Date();
  dates.push(
    new Date(
      now.getFullYear() + "/" + (now.getMonth() + 2) + "/" + now.getDate()
    )
  );
  const maxDate = new Date(Math.max.apply(null, dates));
  let dateExpri2 = Number(moment(maxDate, dateFormat));

  const handleDatePickerChange = (date, dateString) => {
    dateExpri = moment(dateString).valueOf();
    //Trien comment out
    // if (dateExpri > dateExpri2) {
    //   alert("The date must not be more 2020/10/10");
    //   setexpriDate(Number(moment(date._i).valueOf()));
    //   return;
    // }
    // if (dateExpri <= dateExpri2) {
    setexpriDate(dateString);
    // }
  };

  const [rowItem, setRowItem] = useState(props.toCode);
  const [isOpenEdit, setIsOpenEdit] = React.useState(true);
  const [statusEdit, setstatusEdit] = useState(true);
  const [textEdit, settextEdit] = useState("編集");

  const handleOpenEdit = async (e) => {
    if (statusEdit) {
      setIsOpenEdit(false);
      setstatusEdit(false);
      settextEdit("確定");
    } else {
      updateRowItem();
      setIsOpenEdit(true);
      setstatusEdit(true);
      settextEdit("編集");
    }
  };

  const updateRowItem = async () => {
    if (!points) {
      alert("Points not null");
      return;
    }
    // debugger
    const newList3 = {
      target: props.codeId.target,
      memo: props.codeId.memo,
      card: props.codeId.card,
      serialCodes: props.codeId.serialCodes.map((item) => {
        if (props.index === item.position) {
          const updatedItem = {
            position: props.index,
            code: codeProps,
            point: points,
            expirationTime: expriDate,
            status: statuschange,
          };
          return updatedItem;
        }
        return item;
      }),
    };
    console.log(newList3, "newList3");

    dispatch(setCodes(newList3));
  };

  return (
    <React.Fragment>
      <StyledBodyCellIndex>{props.index + 1}</StyledBodyCellIndex>
      <StyledBodyCell>
        <FormFields>
          <Input
            isOpenEdit={isOpenEdit}
            value={codeProps}
            disabled='true'
          ></Input>
        </FormFields>
      </StyledBodyCell>
      <StyledBodyCell
        style={{
          minWidth: "150px",
          width: "100%",
        }}
      >
        <Select
          options={pointsSelectOptions}
          disabled={isOpenEdit}
          labelKey='name'
          valueKey='value'
          placeholder=''
          value={{ value: points }}
          searchable={false}
          onChange={handleChangePoints}
        />
      </StyledBodyCell>
      <StyledBodyCell>
        <FormFields>
          <DatePicker
            style={{
              width: "100%",
            }}
            value={moment(expriDate)}
            allowClear={false}
            format={dateFormat}
            disabled={isOpenEdit}
            onChange={(date, dateString) => {
              handleDatePickerChange(date, dateString);
            }}
          />
        </FormFields>
      </StyledBodyCell>
      <StyledBodyCell
        style={{
          minWidth: "150px",
          width: "100%",
        }}
      >
        <Select
          options={statusSelectOptions}
          disabled={isOpenEdit}
          labelKey='name'
          valueKey='value'
          placeholder=''
          value={{ value: statuschange }}
          searchable={false}
          onChange={handleChangeStatus}
        />
      </StyledBodyCell>
      <StyledBodyCell>
        <Button
          style={
            textEdit === "編集"
              ? {
                  width: "100%",
                  height: 30,
                  backgroundColor: "#cccc",
                  color: "#000",
                  padding: 0,
                }
              : {
                  width: "100%",
                  height: 30,
                  padding: 0,
                }
          }
          statusEdit={statusEdit}
          onClick={(e) => handleOpenEdit({ rowItem })}
        >
          {textEdit}
        </Button>
      </StyledBodyCell>
    </React.Fragment>
  );
};
export default FormEditCode;
