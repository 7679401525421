import gql from "graphql-tag";

export const LOGIN = gql`
  mutation login($username: String!, $password: String!) {
    login(loginInput: { username: $username, password: $password }) {
      id
      pwd
      username
      userType
      access_token
      expiresTime
    }
  }
`;
