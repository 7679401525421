/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import {
  StyledTable,
  StyledHeadCell,
  StyledBodyCell,
} from './DetailsHistory.style';
import Button from '../../components/Button/Button';
import { useDispatch } from 'react-redux';
import Select from '../../components/Select/Select';
import {
  FormFields,
  FormLabel,
  Error,
} from '../../components/FormFields/FormFields';
import { DatePicker } from 'antd';
import moment from 'moment';
import { UPDATE_SERIAL_CODE } from '../../graphql/mutation/updateSerialCode';
import { useMutation } from '@apollo/react-hooks/lib/useMutation';
import { notification, Space } from 'antd';

const dateFormat = 'YYYY/MM/DD';

const pointsSelectOptions = [
  { value: '1', name: '1' },
  { value: '2', name: '2' },
  { value: '3', name: '3' },
  { value: '4', name: '4' },
  { value: '5', name: '5' },
  { value: '6', name: '6' },
  { value: '7', name: '7' },
  { value: '8', name: '8' },
  { value: '9', name: '9' },
  { value: '10', name: '10' },
];

const statusSelectOptions = [
  { value: 'invalid', name: '無効' },
  { value: 'valid', name: '有効' },
];

const FormEditDetails = (props) => {
  const dispatch = useDispatch();
  const [updateSerialCode] = useMutation(UPDATE_SERIAL_CODE);

  const {
    code: icode,
    point: ipoint,
    expirationTime: ieixpirationTime,
    status: istatus,
    exchangedTime: iexchangedTime,
    exchangedBy: iexchangedBy,
  } = props.toDetails;
  console.log(props.toDetails, 'detailsID');

  const [rowItem, setRowItem] = useState(props.toDetails);

  const [codeProps, setCodesProps] = useState(icode);
  const [points, setPoints] = useState(ipoint);
  const [expriDate, setexpriDate] = useState(
    moment(ieixpirationTime).format(dateFormat)
  );
  const [statuschange, setstatuschange] = useState(istatus);
  const [exchangedTime, setexchangedTime] = useState(iexchangedTime);
  const [exchangedBy, setexchangedBy] = useState(iexchangedBy);
  const [textEdit, settextEdit] = useState('編集');
  const [statusEdit, setstatusEdit] = useState(true);
  const [isOpenEdit, setIsOpenEdit] = React.useState(true);
  const [isLoadingConfirm, setisLoadingConfirm] = useState(false);

  const handleChangePoints = ({ value }) => {
    setPoints(value[0].value);
  };
  const handleDatePickerChange = (date, dateString) => {
    setexpriDate(dateString);
  };
  const handleChangeStatus = ({ value }) => {
    setstatuschange(value[0].value);
  };
  const handleOpenEdit = async (rowItem) => {
    if (statusEdit) {
      setIsOpenEdit(false);
      setstatusEdit(false);
      settextEdit('確定');
    } else {
      updateRowItem(rowItem);
      setIsOpenEdit(true);
      setstatusEdit(true);
      settextEdit('編集');
    }
  };

  const updateRowItem = async (rowItem) => {
    setisLoadingConfirm(true);
    const newRowSerialCode = {
      id: rowItem.id,
      point: Number(points),
      expirationTime: expriDate,
      status: statuschange,
    };

    await updateSerialCode({
      variables: {
        updateCode: newRowSerialCode,
      },
    })
      .then((res) => {
        console.log(res.data, 'dataaaaa');
        setisLoadingConfirm(false);
        notification['success']({
          message: 'データ更新に成功しました。',
          description: '',
        });
        // window.location.reload(false);
      })
      .catch((err) => {
        setisLoadingConfirm(false);
        notification['error']({
          message: 'Change error',
          description: '',
        });
      });
  };
  return (
    <React.Fragment>
      <StyledBodyCell style={{ textAlign: 'center' }}>
        {props.index + 1}
      </StyledBodyCell>
      <StyledBodyCell style={{ textAlign: 'center' }}>
        {codeProps}
      </StyledBodyCell>
      <StyledBodyCell
      // style={{
      //   minWidth: "150px",
      //   width: "100%",
      // }}
      >
        <Select
          options={pointsSelectOptions}
          disabled={isOpenEdit}
          labelKey='name'
          valueKey='value'
          placeholder=''
          value={{ value: points }}
          searchable={false}
          onChange={handleChangePoints}
        />
      </StyledBodyCell>
      <StyledBodyCell>
        <FormFields>
          <DatePicker
            style={{
              width: '100%',
            }}
            value={moment(expriDate)}
            format={dateFormat}
            disabled={isOpenEdit}
            allowClear={false}
            onChange={(date, dateString) => {
              handleDatePickerChange(date, dateString);
            }}
          />
        </FormFields>
      </StyledBodyCell>
      <StyledBodyCell
      // style={{
      //   minWidth: "150px",
      //   width: "100%",
      // }}
      >
        <Select
          options={statusSelectOptions}
          disabled={isOpenEdit}
          labelKey='name'
          valueKey='value'
          placeholder=''
          value={{ value: statuschange }}
          searchable={false}
          onChange={handleChangeStatus}
        />
      </StyledBodyCell>
      <StyledBodyCell style={{ textAlign: 'center' }}>
        {/* {(exchangedTime ? exchangedTime.substring(0, 10) : "") +
          (exchangedTime ? " " + exchangedTime.substring(11, 16) : "")} */}
        {exchangedTime
          ? moment(Date.parse(exchangedTime)).format('YYYY-MM-DD hh:mma')
          : ''}
      </StyledBodyCell>
      <StyledBodyCell style={{ textAlign: 'center' }}>
        {exchangedBy?.username}{' '}
      </StyledBodyCell>

      <StyledBodyCell>
        {!exchangedTime && (
          <Button
            style={
              textEdit === '編集'
                ? {
                    width: '100%',
                    height: 24,
                    backgroundColor: '#cccc',
                    color: '#000',
                    fontSize: 12,
                    padding: 0,
                  }
                : {
                    width: '100%',
                    height: 24,
                    fontSize: 12,
                    padding: 0,
                  }
            }
            isLoading={isLoadingConfirm}
            statusEdit={statusEdit}
            onClick={(e) => handleOpenEdit(rowItem)}
          >
            {textEdit}
          </Button>
        )}
      </StyledBodyCell>
    </React.Fragment>
  );
};
export default FormEditDetails;
