import { IGcode } from '../models';

const SET_CHANGE_CODE = 'SET_CHANGE_CODE';

export const setCodesChange = (changeCodes: IGcode) => (dispatch: any) => {
  dispatch({ type: SET_CHANGE_CODE, changeCodes });
};

const initialState: IGcode = {
  code: '',
  point: 0,
  expirationTime: 0,
  status: '',
  position: 0,
};

export default function CodesReducer(state = initialState, action: any) {
  switch (action.type) {
    case SET_CHANGE_CODE:
      return {
        ...state,
        ...action.changeCodes,
      };
    default:
      return state;
  }
}
