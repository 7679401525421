import { styled, withStyle } from 'baseui';
import {
  StyledTable as BaseStyledTable,
  StyledHeadCell as BaseStyledHeadCell,
  StyledBodyCell as BaseStyledCell,
} from 'baseui/table-grid';

export const TableWrapper = styled('div', () => ({
  width: '100%',
  height: 'calc(100vh - 125px)',
}));

export const Imaged = styled('img', () => ({
  maxWidth: '100%',
  maxHeight: '100%',
  display: 'block',
}));

export const ImagedFont = styled('img', () => ({
  width: '160.26px',
  height: '96.98px',
}));

export const StyledCell = styled('td', () => ({
  fontFamily: "'Lato', sans-serif",
  fontWeight: 400,
  color: '#161F6A !important',
  alignSelf: 'center',
  wordWrap: 'break-word',
  padding: '20px',
  height: '55px',
  borderBottom: '1px solid rgba(224, 224, 224, 1)',
  // display: "flex",
  // flexDirection: "column",
  // justifyContent: "center",
  // height: "100%",
  // ":hover": {
  //   backgroundColor: "#EDEDED",
  // },
}));

export const StyledTable = withStyle(BaseStyledTable, () => ({
  borderTopLeftRadius: '0 !important',
  borderTopRightRadius: '0 !important',
  borderBottomLeftRadius: '0 !important',
  borderBottomRightRadius: '0 !important',
  alignContent: 'start',
}));
export const StyledHeadCell = withStyle(BaseStyledHeadCell, () => ({
  fontFamily: "'Lato', sans-serif",
  fontWeight: 700,
  color: '#161F6A !important',
  alignItems: 'center',
  justifyContent: 'center',
  boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
  borderColor: 'rgba(0, 0, 0, 0.12)',
  alignSelf: 'start',
  zIndex: 1,
}));

export const StyledBodyCell = withStyle(BaseStyledCell, () => ({
  fontFamily: "'Lato', sans-serif",
  fontWeight: 400,
  justifyContent: 'center',
  alignItems: 'center',
  color: '#161F6A !important',
  alignSelf: 'center',
}));
export const StyledBodyCellIndex = withStyle(BaseStyledCell, () => ({
  marginLeft: '10px',
  fontFamily: "'Lato', sans-serif",
  fontWeight: 400,
  justifyContent: 'center',
  alignItems: 'center',
  color: '#161F6A !important',
  alignSelf: 'center',
}));
