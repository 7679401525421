/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
import Moment from "react-moment";
import { styled, withStyle } from "baseui";
import {
  Grid,
  Row as Rows,
  Col as Column,
} from "../../components/FlexBox/FlexBox";
import Input from "../../components/Input/Input";
import Select from "../../components/Select/Select";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { Wrapper, Header, Heading } from "../../components/WrapperStyle";
import Button from "../../components/Button/Button";
import Checkbox from "../../components/CheckBox/CheckBox";
import { useHistory } from "react-router-dom";

import {
  StyledTable,
  StyledHeadCell,
  StyledBodyCell,
} from "./DetailsHistory.style";
import { TableWrapper } from "./DetailsHistory.style";

import NoResult from "../../components/NoResult/NoResult";
import { HISTORY } from "../../settings/constants";
import { GET_DETAILS_CODEGROUPS } from "../../graphql/query/detailsSerialCodeGroups.query";
import { textAlign } from "html2canvas/dist/types/css/property-descriptors/text-align";
import FormEditDetails from "./FormEditDetails";

const Col = withStyle(Column, () => ({
  "@media only screen and (max-width: 767px)": {
    marginBottom: "20px",

    ":last-child": {
      marginBottom: 0,
    },
  },
}));

const Row = withStyle(Rows, () => ({
  "@media only screen and (min-width: 768px)": {
    alignItems: "center",
  },
}));

const ImageWrapper = styled("div", ({ $theme }) => ({
  width: "38px",
  height: "38px",
  overflow: "hidden",
  display: "inline-block",
  borderRadius: "20px",
  backgroundColor: $theme.colors.backgroundF7,
}));

const Image = styled("img", () => ({
  width: "100%",
  height: "auto",
}));

const sortByOptions = [
  { value: "highestToLowest", label: "Highest To Lowest" },
  { value: "lowestToHighest", label: "Lowest To Highest" },
];

export default function DetailsHistory() {
  // const { data, error, refetch } = useQuery(GET_CUSTOMERS);
  let idGroupsCode = window.location.pathname;
  let idgroupsCodeParse = idGroupsCode.substring(17);

  const { data } = useQuery(GET_DETAILS_CODEGROUPS, {
    variables: { id: idgroupsCodeParse },
  });

  let history = useHistory();

  const [stock, setStock] = useState([]);
  const [search, setSearch] = useState([]);

  const [filteredStatus, setFilteredStatus] = useState("");

  // if (error) {
  //   return <div>Error! {error.message}</div>;
  // }

  // function handleSort({ value }) {
  //   setStock(value);
  //   if (value.length) {
  //     refetch({
  //       sortBy: value[0].value,
  //     });
  //   } else {
  //     refetch({
  //       sortBy: null,
  //     });
  //   }
  // }
  // function handleSearch(event) {
  //   const value = event.currentTarget.value;
  //   console.log(value, 'cus val');

  //   setSearch(value);
  //   refetch({ searchBy: value });
  // }

  const handleChangeExchangeStatus = ({ value }) => {
    setFilteredStatus(value[0].value);
  };

  return (
    <Grid fluid={true}>
      <Row>
        <Col md={12}>
          <Header
            style={{
              marginBottom: 10,
              boxShadow: "0 0 5px rgba(0, 0 ,0, 0.05)",
            }}
          >
            <Col md={2}>
              <Heading>絞り込み</Heading>
            </Col>
            <Col md={4}>
              <Select
                options={[
                  { name: "すべて", value: "" },
                  { name: "交換済", value: "交換済" },
                  { name: "未交換", value: "未交換" },
                ]}
                labelKey='name'
                valueKey='value'
                placeholder=''
                value={{ value: filteredStatus }}
                searchable={false}
                onChange={handleChangeExchangeStatus}
              />
            </Col>
            <Col md={6}></Col>
          </Header>

          <Wrapper style={{ boxShadow: "0 0 5px rgba(0, 0 , 0, 0.05)" }}>
            <TableWrapper>
              <StyledTable $gridTemplateColumns='minmax(50px, 70px) minmax(140px, 180px) minmax(120px, 160px) minmax(150px, 210px) minmax(140px, 160px) minmax(120px, auto) minmax(100px, auto) minmax(120px, 120px)'>
                <StyledHeadCell>＃</StyledHeadCell>
                <StyledHeadCell>シリアルコード</StyledHeadCell>
                <StyledHeadCell>ポイント数</StyledHeadCell>
                <StyledHeadCell>有効期限</StyledHeadCell>
                <StyledHeadCell>ステータス</StyledHeadCell>
                <StyledHeadCell>交換日時</StyledHeadCell>
                <StyledHeadCell>交換者</StyledHeadCell>
                <StyledHeadCell>操作</StyledHeadCell>

                {data ? (
                  data.serialCodes.length ? (
                    data.serialCodes.map((item, index) => {
                      if (
                        !filteredStatus ||
                        (filteredStatus === "交換済" && item.exchangedTime) ||
                        (filteredStatus === "未交換" && !item.exchangedTime)
                      )
                        return (
                          <FormEditDetails
                            toDetails={item}
                            index={index}
                            codeId={data.serialCodes}
                          />
                        );
                      return null;
                    })
                  ) : (
                    <NoResult
                      hideButton={false}
                      style={{
                        gridColumnStart: "1",
                        gridColumnEnd: "one",
                      }}
                    />
                  )
                ) : null}
              </StyledTable>
            </TableWrapper>
          </Wrapper>
        </Col>
      </Row>
      <Row style={{ height: 30, padding: 0, margin: 0 }}>
        <Col md={3}>
          <h4>{`生成数: ${data?.serialCodes.length}`}</h4>
        </Col>
        <Col md={3}>
          <h4>{`交換済: ${
            data?.serialCodes.filter((item) => {
              return item.exchangedTime;
            }).length
          }`}</h4>
        </Col>
        <Col md={3}>
          <h4>{`未交換: ${
            data?.serialCodes.filter((item) => {
              return !item.exchangedTime;
            }).length
          }`}</h4>
        </Col>
        <Col md={3}>
          <h4>{`交換率: ${(
            (100 *
              data?.serialCodes.filter((item) => {
                return item.exchangedTime;
              }).length) /
            data?.serialCodes.length
          ).toFixed(0)}%`}</h4>
        </Col>
      </Row>
      <Row style={{ height: 30 }}>
        <Col md={3}>
          <Row>
            <Col md={6}>
              <Button
                style={{ height: 30, width: 80, padding: 0 }}
                onClick={() => history.push(HISTORY)}
              >
                戻る
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Grid>
  );
}
