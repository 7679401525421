/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useEffect, useCallback, useState } from 'react';
import Moment from 'react-moment';
import { withStyle } from 'baseui';
import {
  Grid,
  Row as Rows,
  Col as Column,
} from '../../components/FlexBox/FlexBox';
import { useDrawerDispatch } from '../../context/DrawerContext';
import Select from '../../components/Select/Select';
import Input from '../../components/Input/Input';
import Button from '../../components/Button/Button';

import { Plus } from '../../components/AllSvgIcon';

import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { Wrapper, Header, Heading } from '../../components/WrapperStyle';

import {
  TableWrapper,
  StyledTable,
  StyledHeadCell,
  StyledBodyCell,
} from './ListOfCodes.style';

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
} from 'baseui/modal';
import NoResult from '../../components/NoResult/NoResult';
import { useHistory } from 'react-router-dom';
import '../../containers/ListOfCodes/ListOfCodes.css';

import {
  DASHBOARD,
  PREVIEWEXPORTPDF,
  LOGIN,
  DESIGNUIQRCODE,
} from '../../settings/constants';
import { connect, useSelector } from 'react-redux';
import { IStore } from '../../models';
import { StatefulSelect } from 'baseui/select';
import { StyledCell2 } from '../Category/Category.style';
import moment from 'moment';
import {
  FormFields,
  FormLabel,
  Error,
} from '../../components/FormFields/FormFields';
import { Code } from 'react-content-loader';
import { DatePicker } from 'antd';
import { GENERATE_CODE_QUERY } from '../../graphql/query/generateSerialCodes.query';
import { CREATE_SERIAL_CODE } from '../../graphql/mutation/createcodes.mutation';

import { notification, Space } from 'antd';
import { setCodes } from '../../redux/addCodes';
import FormEditCode from './FormEditCodes';

const Col = withStyle(Column, () => ({
  '@media only screen and (max-width: 767px)': {
    marginBottom: '20px',

    ':last-child': {
      marginBottom: 0,
    },
  },
}));

const Row = withStyle(Rows, () => ({
  '@media only screen and (min-width: 768px)': {
    alignItems: 'center',
  },
}));

const ListOfCodes = (props) => {
  const dispatch = useDrawerDispatch();
  let history = useHistory();

  const [isInserting, setIsInserting] = useState(false);

  const openDrawer = useCallback(
    () =>
      dispatch({ type: 'OPEN_DRAWER', drawerComponent: 'STAFF_MEMBER_FORM' }),
    [dispatch]
  );

  const BacktoGenerate = () => {
    history.goBack();
  };

  const [generateCodes] = useMutation(CREATE_SERIAL_CODE);
  const {
    target: itarget,
    expirationTime,
    memo: imemo,
    card: icard,
  } = props.icodeTodos;
  const ConfirmInsertIntoDB = async () => {
    setIsInserting(true);
    const newGenerateCode = {
      target: itarget,
      memo: imemo,
      card: icard,
      serialCodes: props.icodeTodos.serialCodes?.map((item) => ({
        code: item.code,
        point: parseFloat(item.point),
        expirationTime: item.expirationTime,
        status: item.status,
      })),
    };

    await generateCodes({
      variables: { codes: newGenerateCode },
    })
      .then((res) => {
        console.log(res.data, 'newList5');
        notification['success']({
          message: 'データベースにシリアルコード追加成功しました。',
          description: '',
        });
      })
      .catch((err) => {
        notification['error']({
          message: 'Add error',
          description: '',
        });
      });
    setIsInserting(false);
    history.replace(PREVIEWEXPORTPDF);
  };

  return (
    <Grid fluid={true}>
      <Row>
        <Col md={12}>
          <Wrapper style={{ boxShadow: '0 0 5px rgba(0, 0 , 0, 0.05)' }}>
            <TableWrapper>
              <StyledTable $gridTemplateColumns='minmax(70px, 100px) minmax(170px, auto) minmax(150px, auto) minmax(140px, auto) minmax(150px, auto) minmax(120px, 150px)'>
                <StyledHeadCell>＃</StyledHeadCell>
                <StyledHeadCell>シリアルコード</StyledHeadCell>
                <StyledHeadCell>ポイント数</StyledHeadCell>
                <StyledHeadCell>有効期限</StyledHeadCell>
                <StyledHeadCell>ステータス</StyledHeadCell>
                <StyledHeadCell>操作</StyledHeadCell>

                {props.icodeTodos ? (
                  props.icodeTodos.serialCodes.map((item, index) => (
                    <FormEditCode
                      toCode={item}
                      index={index}
                      codeId={props.icodeTodos}
                    />
                  ))
                ) : (
                  <NoResult />
                )}
              </StyledTable>
            </TableWrapper>
          </Wrapper>
        </Col>
      </Row>
      <Row>
        <Col md={12} style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            style={{ width: 120, background: '#fff', color: '#000' }}
            onClick={BacktoGenerate}
            disabled={isInserting}
          >
            戻る
          </Button>
          <Button
            style={{ width: 120 }}
            className='export-pdf'
            isLoading={isInserting}
            onClick={ConfirmInsertIntoDB}
          >
            確定する
          </Button>
        </Col>
      </Row>
    </Grid>
  );
};

const mapStatetoProps = (state) => {
  return {
    icodeTodos: state.addCodes,
  };
};

export default connect(mapStatetoProps, null)(ListOfCodes);
