import gql from "graphql-tag";

export const UPDATE_SERIAL_CODE = gql`
  mutation updateSerialCode($updateCode: UpdateSerialCodeInput!) {
    updateSerialCode(serialCode: $updateCode) {
      point
      expirationTime
      status
    }
  }
`;
