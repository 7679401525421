import gql from "graphql-tag";

export const CREATE_SERIAL_CODE = gql`
  mutation createSerialCodes($codes: CreateSerialCodeGroupInput!) {
    createSerialCodes(serialCodeGroup: $codes) {
      target
      memo
      card
      serialCodes {
        code
        point
        expirationTime
        status
      }
    }
  }
`;
